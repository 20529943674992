import { useApiQuery, useApiMutation } from "../services/react-query";
import { webConfig } from "../../config.js";

export const useGetAllAttendees = (params) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["attendees", params],
    },
    fetchOptions: {
      url: "/attendees",
      method: "GET",
      params,
    },
  });
};

export const useCreateAttendee = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: "/attendees",
      method: "POST",
    },
    successMessage: {
      title: "Attendee was created successfully",
    },
  });
};

export const useUpdateAttendee = ({ attendeeId }) => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: `/attendees/${attendeeId}`,
      method: "PATCH",
    },
    successMessage: {
      title: "Attendee was updated successfully",
    },
  });
};

export const useGetAttendee = ({ attendeeId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["attendees", attendeeId],
    },
    fetchOptions: {
      url: `/attendees/${attendeeId}`,
      method: "GET",
    },
  });
};
export const useSendEmailToAttendees = (params) => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendeeEmail", params],
    },
    fetchOptions: {
      url: `/attendees/send-email`,
      method: "POST",
      body: params,
    },
    successMessage: {
      title: "Email was sent successfully",
    },
  });
};

export const useImportAttendees = () => {
  return useApiMutation({
    queryOptions: {
      queryKey: ["attendees"],
    },
    fetchOptions: {
      url: "/attendees/import",
      method: "POST",
    },
    successMessage: {
      title: "Attendees were imported successfully",
    },
  });
};

export const useGetTicketHistory = ({ eventId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketHistory", eventId] },
    fetchOptions: {
      url: `/attendees/register/history?eventId=${eventId}`,
      method: "GET",
    },
  });
};

export const useGetTicketInfo = ({ eventId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketInfo", eventId], enabled: !!eventId },
    fetchOptions: {
      url: `/attendees/register/summary?eventId=${eventId}`,
      method: "GET",
    },
  });
};

export const useGetTicketDownloadTemplate = () => {
  return useApiQuery({
    queryOptions: { queryKey: ["ticketDownloadTemplate"] },
    fetchOptions: {
      url: "/email/template/ticketDownload",
      method: "GET",
      params: {
        parsedEmailBody: "",
        baseApiUrl: webConfig.baseUrl,
        qrCode: "",
      },
    },
  });
};

export const useDeleteAttendee = () =>
  useApiMutation({
    mutationOptions: { mutationKey: "useDeleteAttendee" },
    fetchOptions: { url: `/attendees`, method: "DELETE" },
    invalidateQueryKey: "attendees",
    successMessage: { title: "Attendee has been deleted", color: "green" },
  });

export const useGetAttendeeColumns = ({ eventId }) => {
  return useApiQuery({
    queryOptions: {
      queryKey: ["columns"],
    },
    fetchOptions: {
      url: `/attendees/columns/${eventId}`,
      method: "GET",
    },
  });
};

export const useUpdateColumnVisibility = ({ eventId, columns }) => {
  return useApiMutation({
    queryOptions: { queryKey: ["columns"] },
    fetchOptions: {
      url: `/attendees/columns/${eventId}`,
      method: "PATCH",
      body: { columns },
    },
  });
};

export const useMakeCustomColumns = ({ eventId, columns }) => {
  return useApiMutation({
    invalidateQueryKey: "attendees",
    fetchOptions: {
      url: `/attendees/custom-columns/${eventId}`,
      method: "POST",
      body: { columns },
    },
  });
};

export const useGetCustomFields = ({ eventId, attendeeId }) => {
  return useApiQuery({
    queryOptions: { queryKey: ["customFields"] },
    fetchOptions: {
      url: attendeeId
        ? `/attendees/custom-columns/${eventId}?attendeeId=${attendeeId}`
        : `/attendees/custom-columns/${eventId}`,
      method: "GET",
    },
  });
};

export const useUpdateCustomFields = ({ eventId, attendeeId, data }) => {
  return useApiMutation({
    fetchOptions: {
      url: `/attendees/custom-columns/${eventId}`,
      method: "PATCH",
      body: { attendeeId, data },
    },
  });
};
