import {
  Container,
  LoadingOverlay,
  NativeSelect,
  rem,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import { useUpdateAttendee } from "../../api/attendees";

const data = [
  { value: "RSD", label: "🇷🇸 RSD" },
  { value: "EUR", label: "🇪🇺 EUR" },
  { value: "USD", label: "🇺🇸 USD" },
];

export function CurrencyInput({ amountValue, attendeeId, disabled = false }) {
  const [amount, setAmount] = useState(amountValue);

  const { mutate, isPending } = useUpdateAttendee({ attendeeId });

  const handleAmountChange = (event) => {
    setAmount({
      ...amount,
      amountPaid: event.currentTarget.value,
      currency: amount.currency ?? "RSD",
    });
  };

  const handleMutate = () => {
    if (amount !== amountValue) {
      mutate(amount, { onError: () => setAmount(amountValue) });
    }
  };

  const select = (
    <NativeSelect
      onChange={(event) => {
        setAmount({ ...amount, currency: event.currentTarget.value });
      }}
      onBlur={handleMutate}
      data={data}
      value={amount.currency}
      rightSectionWidth={28}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: rem(92),
          marginRight: rem(-2),
        },
      }}
      disabled={disabled}
    />
  );

  return (
    <Container fluid px={0}>
      <LoadingOverlay visible={isPending} zIndex={1000} />
      <TextInput
        name="amountPaid"
        onChange={handleAmountChange}
        onBlur={handleMutate}
        type="number"
        placeholder="1000"
        value={amount.amountPaid}
        width={150}
        rightSection={select}
        rightSectionWidth={92}
        disabled={disabled}
      />
    </Container>
  );
}
