import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Badge,
  Button,
  Container,
  Fieldset,
  Flex,
  Paper,
  Select,
  Text,
  TextInput as TextInputMantine,
  MultiSelect as MultiSelectMantine,
  Anchor,
} from "@mantine/core";
import "@mantine/dates/styles.css";
import { useState } from "react";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  DateTimePicker,
  TextInput,
  MultiSelect,
  Textarea,
  Select as FormSelect,
} from "react-hook-form-mantine";
import { fullFormats } from "ajv-formats/dist/formats";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

import { usePatchSession, useCancelSession } from "../../../api/session";
import { formatDateTime } from "../utils/formatDateTime";
import deleteModal from "../../deleteModal";
import { formatTagLabel } from "../utils/formatTagLabel";

function Form({
  data,
  enableActions,
  speakerOptions,
  sponsorOptions,
  tagOptions,
  sessionTags,
  locationsOptions,
  event,
}) {
  const {
    id,
    title,
    description,
    starting,
    ending,
    location,
    speakers,
    sponsorId,
    links,
  } = data;

  const defaultSponsor = sponsorOptions?.find(
    (s) => Number(s.value) === sponsorId
  );

  const tagSubtypes = ["dressCode", "sessionType", "topic"];
  const extractTagIdsBySubtype = (tags, subtype) =>
    tags
      ?.filter((tag) => tag.subtype === subtype)
      .map((tag) => tag.id.toString()) || [];

  const defaultTags = tagSubtypes.reduce((acc, subtype) => {
    acc[subtype] = extractTagIdsBySubtype(sessionTags, subtype);
    return acc;
  }, {});

  const formatTagOptions = (tags, subtype) =>
    tags
      ?.filter((tag) => tag.subtype === subtype)
      .map((tag) => ({
        label: tag.title,
        value: tag.id.toString(),
      })) || [];

  const tagSelectOptions = tagSubtypes.reduce((acc, subtype) => {
    acc[subtype] = formatTagOptions(tagOptions, subtype);
    return acc;
  }, {});

  const { mutate, isSuccess, isPending: isLoading } = usePatchSession(id);

  const {
    mutate: cancel,
    isError: isCancelError,
    isSuccess: isCancelSuccess,
    error: cancelError,
  } = useCancelSession(id, data.canceledAt !== null);

  const {
    register,
    control,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm({
    defaultValues: {
      title: title || "",
      description: description || "",
      locationId: location?.id ? location.id.toString() : "",
      starting: starting ? new Date(starting) : undefined,
      ending: ending ? new Date(ending) : undefined,
      speakers: speakers?.map((s) => s.id.toString()) || [],
      links: links?.list || [],
      tags: {
        dressCode: defaultTags.dressCode,
        sessionType: defaultTags.sessionType,
        topic: defaultTags.topic,
      },
    },
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          title: { type: "string", minLength: 1 },
          locationId: { type: "number", minimum: 1 },
          starting: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Starting date is required",
          },
          ending: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Ending date is required",
          },
          tags: {
            type: "object",
            properties: {
              dressCode: { type: "array" },
              sessionType: { type: "array" },
              topic: { type: "array" },
            },
          },
        },
        required: ["title", "locationId", "starting", "ending"],
      },
      {
        validateFormats: true,
        allowUnionTypes: true,
        formats: fullFormats,
        coerceTypes: true,
      }
    ),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const [start, setStart] = useState(new Date(starting));
  const [end, setEnd] = useState(new Date(ending));

  const onSubmit = (data) => {
    data.starting = formatDateTime(dayjs(start).toString());
    data.ending = formatDateTime(dayjs(end).toString());

    data.speakers = data.speakers.map((speaker) => Number(speaker));

    data.links = JSON.stringify({ list: data.links });

    data.tags = ["dressCode", "sessionType", "topic"]
      .reduce((acc, type) => acc.concat(data.tags[type] || []), [])
      .map((tag) => Number(tag));

    mutate(data);
  };

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        {data.canceledAt !== null && (
          <Badge color="red" variant="dot" size="md" ta="right">
            Canceled
          </Badge>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            type="text"
            name="title"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <Textarea
            label="Description"
            name="description"
            control={control}
            mt="md"
            disabled={!enableActions}
            autosize
            minRows={4}
            maxRows={8}
          />
          <FormSelect
            name="locationId"
            label="Location"
            placeholder="Select location"
            data={locationsOptions}
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
            searchable
          />
          {event?.dates?.length > 0 ? (
            <>
              <DateTimePicker
                label="Starting"
                placeholder="Starting"
                name="starting"
                onChange={setStart}
                withAsterisk
                mt="md"
                clearable
                disabled={!enableActions}
                excludeDate={(data) => {
                  const allowedDates = event?.dates.map((date) => date.date);

                  return !allowedDates.includes(
                    dayjs(data).format("YYYY-MM-DD")
                  );
                }}
                hideOutsideDates
                control={control}
              />
              <DateTimePicker
                label="Ending"
                placeholder="Ending"
                name="ending"
                onChange={setEnd}
                withAsterisk
                mt="md"
                clearable
                disabled={!enableActions}
                excludeDate={(date) => {
                  const allowedDates = event?.dates.map((date) => date.date);

                  return (
                    !allowedDates.includes(dayjs(date).format("YYYY-MM-DD")) ||
                    (dayjs(date).isBefore(start) &&
                      dayjs(date).format("YYYY-MM-DD") !==
                        dayjs(start).format("YYYY-MM-DD"))
                  );
                }}
                control={control}
              />
            </>
          ) : (
            <Text mt="md">
              No event dates found. Please{" "}
              <Anchor component={Link} to="/app/events/customize">
                add event dates
              </Anchor>{" "}
              first to create a session.
            </Text>
          )}

          <MultiSelect
            label="Speakers"
            name="speakers"
            control={control}
            data={speakerOptions}
            searchable
            limit={10}
          />
          <Select
            label="Sponsor"
            name="sponsorId"
            control={control}
            data={sponsorOptions}
            defaultValue={defaultSponsor?.value}
            clearable
            searchable
            nothingFoundMessage="Nothing found"
            mt="md"
            onChange={(value) =>
              setValue("sponsorId", Number(value), { shouldDirty: true })
            }
          />
          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link title"
                  {...register(`links.${index}.title`, { defaultValue: "" })}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`, { defaultValue: "" })}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          <Fieldset legend="Tags" mt="md">
            {["dressCode", "sessionType", "topic"].map((type) => (
              <MultiSelectMantine
                key={type}
                label={`Tags - ${formatTagLabel(type)}`}
                name={`tags.${type}`}
                placeholder={`Select ${formatTagLabel(type)} tags`}
                data={tagSelectOptions[type] || []}
                defaultValue={defaultTags[type] || []}
                mt="md"
                searchable
                nothingFoundMessage="No tags found"
                clearable
                onChange={(value) =>
                  setValue(`tags.${type}`, value, {
                    shouldDirty: true,
                  })
                }
              />
            ))}
          </Fieldset>

          {!enableActions && (
            <Text size="md" c="red" mt="md">
              Only system admins can edit sessions!
            </Text>
          )}

          <Flex justify="space-between" mt="md">
            <Button
              type="submit"
              loading={isLoading}
              disabled={!enableActions || !isDirty || isSuccess}
            >
              Edit session
            </Button>

            <Button
              size="sm"
              color={data.canceledAt === null ? "red" : "green"}
              onClick={() =>
                deleteModal(
                  data,
                  `${data.canceledAt ? "restore" : "cancel"} '${data.title}' session`,
                  cancel,
                  isCancelError,
                  cancelError,
                  isCancelSuccess
                )
              }
            >
              {data.canceledAt === null ? "Cancel session" : "Restore session"}
            </Button>
          </Flex>
        </form>
      </Paper>
    </Container>
  );
}

export default Form;
