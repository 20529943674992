import { useParams } from "react-router-dom";
import { useState } from "react";
import { useGetAttendee, useUpdateAttendee } from "../../api/attendees";
import { useGetUser } from "../../api/user";
import { EditUser } from "../Users/EditUser";
import { Button, Container, Fieldset } from "@mantine/core";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";
import { capitalize } from "../../utils/textUtils";
import {
  useGetCustomFields,
  useUpdateCustomFields,
  useGetAttendeeColumns,
} from "../../api/attendees";
import { extractDirtyData } from "../../utils/dirtyData";

function Edit() {
  const { attendeeId } = useParams();

  const { data: attendee } = useGetAttendee({ attendeeId });

  const { data: user } = useGetUser(attendee?.userId);

  return (
    <>
      {user && <EditUser user={user} />}
      {attendee && (
        <>
          <EditAttendeeTag attendee={attendee} />
          <EditAttendeeCustomFields attendee={attendee} />
        </>
      )}
    </>
  );
}

function EditAttendeeTag({ attendee }) {
  const { data } = useGetPeopleTagsBySubtype({
    eventId: attendee.eventId,
    subtype: "categoryOfUser",
  });

  const findAttendeeCategory = attendee.tags.find(
    (tag) => tag.subtype === "categoryOfUser"
  );

  const { mutate, isPending } = useUpdateAttendee({ attendeeId: attendee.id });

  const defaultValues = {
    categoryOfUser: findAttendeeCategory?.id
      ? String(findAttendeeCategory.id)
      : "",
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues,
  });

  const handleTagUpdate = (data) => {
    mutate(
      {
        categoryOfUser: data.categoryOfUser,
      },
      {
        onSuccess: () => {
          reset({ categoryOfUser: data.categoryOfUser });
        },
        onError: () => {
          // Handle error
          reset({ ...defaultValues });
        },
      }
    );
  };

  return (
    <Container my="md">
      <form onSubmit={handleSubmit((data) => handleTagUpdate(data))}>
        <Fieldset legend="Attendee category">
          <Select
            label="Category"
            placeholder="Select category"
            data={data?.map((tag) => ({
              value: String(tag.id),
              label: capitalize(tag.title),
            }))}
            control={control}
            name="categoryOfUser"
          />
        </Fieldset>
        <Button
          type="submit"
          variant="light"
          color="blue"
          mt="md"
          disabled={!isDirty}
          loading={isPending}
        >
          Change category
        </Button>
      </form>
    </Container>
  );
}

const EditAttendeeCustomFields = ({ attendee }) => {
  const [errors, setErrors] = useState({});
  const { data: customFieldsData } = useGetCustomFields({
    eventId: attendee.eventId,
    attendeeId: attendee.id,
  });

  const { data: columns } = useGetAttendeeColumns({
    eventId: attendee.eventId,
  });

  const customColumns = columns
    ? columns?.filter(
        (col) =>
          col.isVisible &&
          ![
            "picture",
            "firstName",
            "lastName",
            "email",
            "status",
            "category",
            "paid",
            "amountPaid",
            "isApproved",
          ].includes(col.name)
      )
    : [];

  const { mutate, isPending } = useUpdateCustomFields({
    eventId: attendee.eventId,
    attendeeId: attendee.id,
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isDirty },
    reset,
  } = useForm({
    defaultValues: {
      customFields: customFieldsData?.reduce((acc, field) => {
        acc[field.name] = field.value;
        return acc;
      }, {}),
    },
  });

  const handleCustomFieldsUpdate = (data) => {
    const dirtyData = extractDirtyData(data, dirtyFields);

    mutate(
      {
        data: dirtyData,
        attendeeId: attendee.id,
        eventId: attendee.eventId,
      },
      {
        onSuccess: () => {
          reset(data);
        },
      }
    );

    setErrors({});
  };

  return (
    <Container my="md">
      <form onSubmit={handleSubmit((data) => handleCustomFieldsUpdate(data))}>
        <Fieldset legend="Custom fields" name={"customFields"}>
          {customColumns?.map((field) => {
            // Find the default value for the custom field
            const customField = customFieldsData?.find(
              (cf) => cf.name === field.name && cf.attendeeId === attendee.id
            );

            return (
              <TextInput
                key={field.name}
                label={field.name}
                control={control}
                name={field.name}
                defaultValue={customField?.value || ""}
                error={errors[field.name]}
              />
            );
          })}
        </Fieldset>

        <Button
          type="submit"
          variant="light"
          color="blue"
          mt="md"
          disabled={!isDirty}
          loading={isPending}
        >
          Update custom fields
        </Button>
      </form>
    </Container>
  );
};

export default Edit;
